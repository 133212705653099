<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        수동 경기 등록
      </h4>
      <hr>
<!--      {{ newRowData }}-->
      <b-table
        responsive
        show-empty
        hover
        empty-text="No matching records found"
        :items="newRowData"
        :fields="fields1"
        style="text-align: center !important; font-size: small;"
      >
        <template #cell(site)="data">
          <div
            class="center"
            style="text-align: center; color:rgb(229,166,48); min-width: 7rem"
            size="sm"
          >
            {{ siteSelected }}
          </div>
        </template>
        <template #cell(menuName)="data">
          <div class="center" style="text-align: center; color:rgb(229,166,48); min-width: 7rem">
            {{ getMenuNameText(newRowData[data.index].menuName) }}
          </div>
        </template>
        <!-- 셀별로 입력 폼 설정 -->
        <template #cell(itemName)="data">
          <b-form-select
            class="center"
            style="text-align: center; color:rgb(229,166,48); min-width: 6rem"
            size="sm"
            :options="optionsItem"
            v-model:value="newRowData[data.index].itemName"
            @change="changeItemName(data.item)"
          >
          </b-form-select>
        </template>
        <template #cell(oddsType)="data">
          <b-form-select
            class="center"
            style="text-align: center; color:rgb(229,166,48); min-width: 6rem"
            size="sm"
            :options="selectedOddsType"
            v-model:value="newRowData[data.index].oddsType"
          />
        </template>
        <template #cell(leagueNameCustom)="data">
          <b-form-input
            class="center"
            style="text-align: left; color:rgb(229,166,48); min-width: 6rem"
            size="sm"
            v-model:value="newRowData[data.index].leagueNameCustom ? newRowData[data.index].leagueNameCustom :newRowData[data.index].leagueName"
            v-b-modal.modal-league
            @click="openModal(data)"
          />
        </template>
        <template #cell(homeNameCustom)="data">
          <b-form-input
            class="center"
            style="text-align: left; color:rgb(229,166,48); min-width: 6rem"
            size="sm"
            v-model:value="newRowData[data.index].homeNameCustom ? newRowData[data.index].homeNameCustom :newRowData[data.index].homeName"
            v-b-modal="data.item.leagueName ? 'modal-team' : 'modal-league'"
            @click="openModal(data)"
          />
        </template>
        <template #cell(awayNameCustom)="data">
          <b-form-input
            class="center"
            style="text-align: left; color:rgb(229,166,48); min-width: 6rem"
            size="sm"
            v-model:value="newRowData[data.index].awayNameCustom ? newRowData[data.index].awayNameCustom :newRowData[data.index].awayName"
            v-b-modal="data.item.leagueName ? 'modal-team' : 'modal-league'"
            @click="openModal(data)"
          />
        </template>
        <template #cell(oddsHome)="data">
          <b-form-input
            class=""
            style="min-width: 5rem; max-width: 5rem; text-align: right; color: yellow"
            size="sm"
            v-model:value="newRowData[data.index].oddsHome"
          />
        </template>
        <template #cell(oddsDraw)="data">
          <b-form-input
            class=""
            style="min-width: 5rem; max-width: 5rem; text-align: right; color: yellow"
            size="sm"
            v-model:value="newRowData[data.index].oddsDraw"
          />
        </template>
        <template #cell(oddsAway)="data">
          <b-form-input
            class=""
            style="min-width: 5rem; max-width: 5rem; text-align: right; color: yellow"
            size="sm"
            v-model:value="newRowData[data.index].oddsAway"
          />
        </template>
        <template #cell(handicap)="data">
          <b-form-input
            class=""
            style="min-width: 5rem; max-width: 5rem; text-align: center; color: yellow"
            size="sm"
            placeholder="+-기준"
            v-model:value="newRowData[data.index].handicap"
          />
        </template>
        <template #cell(gameDate)="data">
          <flat-pickr
            class="form-control text-sm-center"
            style="text-align: center; color:rgb(229,166,48); min-width: 8rem; height: 30px; font-size: small"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
            v-model:value="newRowData[data.index].gameDate"
          />
        </template>
        <template
          #cell(edit)="data"
        >
          <div
            class="editClass"
            @click.stop
          >
            <span class="d-inline-flex">
              <div style="min-width: 9rem;">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1"
                  size="sm"
                  variant="outline-success"
                  style="font-size: smaller"
                  @click="copyRow(data.item)"
                >
                  복사
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  style="font-size: smaller;"
                  @click="deleteRowRegiste(data.item)"
                >
                  삭제
                </b-button>
              </div>
            </span>
          </div>
        </template>
      </b-table>
      <hr>
      <!-- 등록  -->
      <div class="text-center pt-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          size="sm"
          @click="submit('createRowRegiste')"
        >
          <feather-icon
            icon="CheckCircleIcon"
            size="12"
          />
          경기등록
        </b-button>
      </div>
    </b-card>
    <!-- 팝업 모달 -->
    <league-info-popup ref="leagueInfoPopup" @value-selected-league="handleValueSelectedLeague"/>
    <team-info-popup ref="teamInfoPopup" @value-selected-team="handleValueSelectedTeam"/>
    <!-- 수동 등록 경기 조회-->
    <b-card>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        수동 경기 목록
      </h4>
      <div class="m-2">
        <!-- Per Page -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="3"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!-- Table -->
      <b-table
        responsive
        show-empty
        hover
        small
        empty-text="No matching records found"
        :per-page="perPage"
        :items="fetchCustomMatchByAdmin"
        :fields="fields2"
        :filter="filter"
        :filter-included-fields="filterOn"
        style="text-align: center !important; font-size: small"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="currentPage"
        @filtered="onFiltered"
        @row-clicked="rowClickHandler"
      >
        <template
          #cell(index)="data"
          size="sm"
          style="text-align: center; min-width: 3rem"
        >
          {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
        </template>
        <template #cell(site)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 4rem"
          >
            {{ data.item.site }}
          </div>
        </template>
        <template #cell(gameDate)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 10rem"
          >
            {{ data.item.gameDate }}
          </div>
        </template>
        <template #cell(category)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 8rem"
          >
            {{ data.item.category }}
          </div>
        </template>
        <template #cell(oddsType)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 8rem"
          >
            {{ data.item.oddsType }}
          </div>
        </template>
        <template #cell(leagueName)="data">
          <div
            size="sm"
            class="text-truncate"
            style="text-align: left; max-width: 12rem"
            v-b-tooltip.hover.bottom.v-danger="data.item.leagueName"
          >
            {{ data.item.leagueName }}
          </div>
        </template>
        <template #cell(homeName)="data">
          <div
            size="sm"
            style="text-align: right; min-width: 15rem"
          >
            {{ data.item.homeName }}
            <span style="display: inline-block; min-width: 3rem; text-align: right; color: yellow">
              {{ formatNumber(data.item.oddsHome) }}
            </span>
          </div>
        </template>
        <template #cell(handicap)="data">
          <div
            v-if="data.item.handicap"
            size="sm"
            style="text-align: center; min-width: 3rem; color: yellow"
          >
            {{ data.item.handicap }}
          </div>
          <div
            v-else-if="data.item.oddsDraw"
            size="sm"
            style="text-align: center; min-width: 3rem; color: yellow"
          >
            {{ formatNumber(data.item.oddsDraw) }}
          </div>
          <div
            v-else
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            VS
          </div>
        </template>
        <template #cell(awayName)="data">
          <div
            size="sm"
            style="text-align: left; min-width: 15rem"
          >
            <span style="display: inline-block; min-width: 3rem; text-align: left; color: yellow">
              {{ formatNumber(data.item.oddsAway) }}
            </span>
            {{ data.item.awayName }}
          </div>
        </template>
        <template #cell(status)="data">
          <div
            v-if="data.item.status === '배팅가능'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: limegreen"
          >
            {{ data.item.status }}
          </div>
          <div
            v-if="data.item.status === '배팅마감'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: red"
          >
            {{ data.item.status }}
          </div>
        </template>
        <template
          #cell(edit)="data"
        >
          <div
            class="editClass"
            @click.stop
          >
            <span class="d-inline-flex">
              <div style="min-width: 8rem;">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  style="font-size: smaller;"
                  type="submit"
                  @click="submit('deleteRowList', data.item)"
                >
                  삭제
                </b-button>
              </div>
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
<!--    <div v-for="(item, index) in newRowData" :key="index">-->
<!--      <div v-for="(value, key) in item" :key="key">-->
<!--        <li><strong>{{ key }}:</strong> <i class="text-success">{{ value }}</i></li>-->
<!--      </div>-->
<!--      <br>-->
<!--    </div>-->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTr,
  BTh,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  VBTooltip,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
const gameStore = createNamespacedHelpers('gameStore')

import {
  FETCH_CUSTOM_MATCH_BY_ADMIN,
  FETCH_GAME_SET_MENU,
  FETCH_GAME_SET_ITEM,
} from '@/store/game/action'
import { CREATE_MANY_CUSTOM_MATCH_BY_ADMIN, UPDATE_CUSTOM_MATCH_BY_ADMIN } from '@/store/game/mutation'

import LeagueInfoPopup from '@/views/game/gamePopups/LeagueInfoPopup'
import TeamInfoPopup from '@/views/game/gamePopups/TeamInfoPopup'
import flatPickr from 'vue-flatpickr-component'

import * as moment from 'moment-timezone'
import Swal from "sweetalert2";
moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTr,
    BTh,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    VBTooltip,
    flatPickr,
    BModal,
    LeagueInfoPopup,
    TeamInfoPopup,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    flatPickr,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      connectedUsers: [],
      socket: null,
      perPage: 15,
      pageOptions: [15, 30, 50],
      sortBy: 'idx',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      today: moment(new Date()).add(1, 'hours').format('YYYY-MM-DD HH:mm'),
      selectedRowIndex: null,
      selectedRowField: null,
      selectdRowItem: null,
      selectedLeagueId: null,
      selectedSports: null,
      selectedItemName: null,
      selectedOddsType: [],
      option1: [
        { value: 'Sports-Prematch', text: '프리매치' },
      ],
      option3: [
        { value: 'Match Winner', text: '승무패' },
        { value: 'Asian Handicap', text: '핸디캡' },
        { value: 'Goals Over/Under', text: '언오버' },
      ],
      option4: [
        { value: 'Home/Away', text: '승무패' },
        { value: 'Asian Handicap', text: '핸디캡' },
        { value: 'Over/Under', text: '언오버' },
      ],
      option5: [
        { value: 'Home/Away', text: '승무패' },
        { value: 'Maps Handicap', text: '핸디캡' },
        { value: 'Total Maps', text: '언오버' },
      ],
      option6: [
        { value: 'Home/Away', text: '승무패' },
        { value: 'Asian Handicap (Games)', text: '핸디캡' },
        { value: 'Over/Under by Games in Match', text: '언오버' },
      ],
      fields1: [
        {
          key: 'site', sortable: false, label: '사이트', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'menuName', sortable: false, label: '메뉴명', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'itemName', sortable: false, label: '종목명', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'oddsType', sortable: false, label: '유형', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'leagueNameCustom', sortable: false, label: '리그', thClass: 'col2', tdClass: 'inner-sm-padding',
        },
        {
          key: 'homeNameCustom', sortable: false, label: '홈팀', thClass: 'col3', tdClass: 'inner-sm-padding',
        },
        {
          key: 'awayNameCustom', sortable: false, label: '어웨이팀', thClass: 'col3', tdClass: 'inner-sm-padding',
        },
        {
          key: 'oddsHome', sortable: false, label: '홈', thClass: 'col5', tdClass: 'inner-sm-padding',
        },
        {
          key: 'oddsDraw', sortable: false, label: '무', thClass: 'col5', tdClass: 'inner-sm-padding',
        },
        {
          key: 'oddsAway', sortable: false, label: '원정', thClass: 'col5', tdClass: 'inner-sm-padding',
        },
        {
          key: 'handicap', sortable: false, label: '기준', thClass: 'col5', tdClass: 'inner-sm-padding',
        },
        {
          key: 'gameDate', sortable: false, label: '경기일시', thClass: 'col3', tdClass: 'inner-sm-padding',
        },
        {
          key: 'edit', sortable: false, label: '설정', thClass: 'col3',
        },
      ],
      fields2: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'site', sortable: false, label: '사이트', thClass: 'col1',
        },
        {
          key: 'gameDate', sortable: false, label: '경기일시', thClass: 'col3',
        },
        {
          key: 'category', sortable: false, label: '카테고리', thClass: 'col2',
        },
        {
          key: 'sports', sortable: false, label: '구분', thClass: 'col2',
        },
        {
          key: 'oddsType', sortable: false, label: '유형', thClass: 'col4',
        },
        {
          key: 'leagueName', sortable: false, label: '리그', thClass: 'col2',
        },
        {
          key: 'homeName', sortable: false, label: '홈', thClass: 'col3', tdClass: 'border border-primary',
        },
        {
          key: 'handicap', sortable: false, label: '무/기준', thClass: 'col5', tdClass: 'border border-primary',
        },
        {
          key: 'awayName', sortable: false, label: '어웨이', thClass: 'col3', tdClass: 'border border-primary',
        },
        {
          key: 'status', sortable: false, label: '상태', thClass: 'col5', tdClass: 'border border-primary',
        },
        {
          key: 'edit', sortable: false, label: '설정', thClass: 'col3',
        },
      ],
      newRowData: [
        {
          site: localStorage.getItem('authSiteSelected'),
          menuName: 'Sports-Prematch',
          itemName: 'soccer',
          oddsType: 'Match Winner',
          country: '',
          leagueId: '',
          leagueName: '',
          leagueNameCustom: '',
          homeId: '',
          homeName: '',
          homeNameCustom: '',
          awayId: '',
          awayName: '',
          awayNameCustom: '',
          oddsHome: 0,
          oddsDraw: 0,
          oddsAway: 0,
          handicap: '',
          gameDate: moment(new Date()).add(1, 'hours').format('YYYY-MM-DD HH:mm'),
        },
      ],
      filterByToday: true,
    }
  },
  computed: {
    ...gameStore.mapGetters({
      fetchCustomMatchByAdmin: 'fetchCustomMatchByAdmin',
      fetchGameSetMenu: 'fetchGameSetMenu',
      fetchGameSetItem: 'fetchGameSetItem',
    }),
    gameStore() {
      return this.$store.state.gameStore.fetchCustomMatchByAdmin
    },
    optionsMenu() {
      const formattedOptions = this.fetchGameSetMenu.map(item => ({
        text: `${item.nameCustom}`,
        value: item.name,
      }))
      return formattedOptions
    },
    optionsItem() {
      const formattedOptions = this.fetchGameSetItem
        .map(item => ({
          text: `${item.nameCustom}`,
          value: item.name,
        })).filter(item => item.value !== 'tennis') // tennis 수동경기 사용안함
      console.log('formattedOptions ::', formattedOptions)
      return formattedOptions
    },
  },
  created() {
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData()
        localStorage.setItem('authSiteSelected', event)
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
        for (let i = 0; i < this.newRowData.length; i++) {
          this.$set(this.newRowData[i], 'site', event)
        }
      },
      immediate: false,
      deep: false,
    },
    selectedLeagueId(selectedLeagueId) {
      this.selectedLeagueId = selectedLeagueId
      this.$refs.teamInfoPopup.fetchData(this.selectedLeagueId, this.selectedSports)
    },
  },
  mounted() {
    this.selectedOddsType = this.option3
    this.fetchData()
  },
  setup() {
  },
  methods: {
    ...gameStore.mapActions({
      $fetchCustomMatchByAdmin: FETCH_CUSTOM_MATCH_BY_ADMIN,
      $fetchGameSetMenu: FETCH_GAME_SET_MENU,
      $fetchGameSetItem: FETCH_GAME_SET_ITEM,
      $createManyCustomMatchByAdmin: CREATE_MANY_CUSTOM_MATCH_BY_ADMIN,
      $updateCustomMatchByAdmin: UPDATE_CUSTOM_MATCH_BY_ADMIN,
    }),
    updateParam(t, e, v, i) {
      const events = {
        t, e, v, i,
      }
      this.$store.commit('gameStore/updateManyPushParam', events)
    },
    changeItemName(itemName) {
      const index = this.newRowData.indexOf(itemName)
      // console.log('itemName:::', itemName.itemName)
      this.$set(this.newRowData[index], 'leagueName', '')
      this.$set(this.newRowData[index], 'homeName', '')
      this.$set(this.newRowData[index], 'awayName', '')
      this.$set(this.newRowData[index], 'leagueNameCustom', '')
      this.$set(this.newRowData[index], 'homeNameCustom', '')
      this.$set(this.newRowData[index], 'awayNameCustom', '')
      this.selectedItemName = itemName.itemName
      this.$refs.leagueInfoPopup.fetchData(this.selectedItemName)

      if (itemName.itemName === 'basketball' || itemName.itemName === 'baseball'
          || itemName.itemName === 'hockey' || itemName.itemName === 'football'
      ) {
        this.selectedOddsType = this.option4
      } else if (itemName.itemName === 'esports') {
        this.selectedOddsType = this.option5
      } else if (itemName.itemName === 'volleyball') {
        this.selectedOddsType = this.option6
      } else {  // soccer
        this.selectedOddsType = this.option3
      }
    },
    async fetchData() {
      const mySite = this.siteSelected
      await this.$fetchCustomMatchByAdmin({
        site: mySite,
        useYn: 'Y',
      })
      this.totalRows = this.fetchCustomMatchByAdmin.length
      await this.$fetchGameSetMenu({
        site: mySite,
      })
      await this.$fetchGameSetItem({
        site: mySite,
        menuName: 'Sports-Prematch',
      })
    },
    async submit(target, rowData) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, rowData)
          // console.log(result.isConfirmed)
          // console.log(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, rowData) {
      try {
        if (target === 'createRowRegiste') {
          await this.$createManyCustomMatchByAdmin(this.newRowData)
        }
        if (target === 'deleteRowList') {
          const mySite = this.siteSelected
          await this.$updateCustomMatchByAdmin({
            site: mySite,
            oddsId: rowData.oddsId,
            useYn: 'N',
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    onRowSelected(items) {
      this.selected = items
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowClickHandler(record) {
    },
    formatNumber(value) {
      if (!value) return '0.00'
      return parseFloat(value).toFixed(2)
    },
    // 행 복사
    copyRow(row) {
      // row를 복사하여 테이블 데이터에 추가
      this.newRowData.push({ ...row })
      // this.newRowData = {...row}
    },
    // 경기 등록 행 삭제
    deleteRowRegiste(row) {
      // 테이블 데이터에서 해당 행 제거
      const index = this.newRowData.indexOf(row)
      if (index !== 0) {
        this.newRowData.splice(index, 1)
      }
    },
    openModal(row) {
      // 행을 클릭할 때 해당 행의 인덱스를 저장
      // console.log('row:::', row)
      this.selectedRowIndex = row.index
      this.selectedRowField = row.field.key
      this.selectedLeagueId = row.item.leagueId
      const rowLeagueId = row.item.leagueId
      if (this.selectedRowField === 'homeName' || this.selectedRowField === 'awayName' ||
          this.selectedRowField === 'homeNameCustom' || this.selectedRowField === 'homeNameCustom'
      ) {
        if (!rowLeagueId) {
          this.showToast('리그를 선택해주세요.')
        }
      }
    },
    closeModal() {
      // 팝업 모달을 닫습니다.
      this.showModal = false
    },
    handleValueSelectedLeague(value) {
      // 모달에서 선택한 값과 선택한 행의 인덱스를 받아옴
      const rowIndex = this.selectedRowIndex
      this.$set(this.newRowData[rowIndex], 'country', value.country)
      this.$set(this.newRowData[rowIndex], 'leagueId', value.leagueId)
      this.$set(this.newRowData[rowIndex], 'leagueName', value.leagueName)
      if (value.leagueNameCustom === null || value.leagueNameCustom === undefined) {
        this.$set(this.newRowData[rowIndex], 'leagueNameCustom', null)
      } else {
        this.$set(this.newRowData[rowIndex], 'leagueNameCustom', value.leagueNameCustom)
      }
      this.selectedSports = value.sports
      // 선택한 행의 인덱스 초기화
      this.selectedRowIndex = null
      this.selectedRowField = null
    },
    handleValueSelectedTeam(value) {
      // 모달에서 선택한 값과 선택한 행의 인덱스를 받아옴
      const rowIndex = this.selectedRowIndex
      const rowField = this.selectedRowField
      // 선택한 행의 team에 값을 바인딩
      if (rowField === 'homeNameCustom') {
        console.log('value.teamNameCustom::', value.teamNameCustom)
        this.$set(this.newRowData[rowIndex], 'homeId', value.teamId)
        this.$set(this.newRowData[rowIndex], 'homeName', value.teamName)
        if (value.teamNameCustom === null || value.teamNameCustom === undefined) {
          this.$set(this.newRowData[rowIndex], 'homeNameCustom', null)
        } else {
          this.$set(this.newRowData[rowIndex], 'homeNameCustom', value.teamNameCustom)
        }
      }
      if (rowField === 'awayNameCustom') {
        this.$set(this.newRowData[rowIndex], 'awayId', value.teamId)
        this.$set(this.newRowData[rowIndex], 'awayName', value.teamName)
        if (value.teamNameCustom === null || value.teamNameCustom === undefined) {
          this.$set(this.newRowData[rowIndex], 'awayNameCustom', null)
        } else {
          this.$set(this.newRowData[rowIndex], 'awayNameCustom', value.teamNameCustom)
        }
      }
      // 선택한 행의 인덱스 초기화
      this.selectedRowIndex = null
      this.selectedRowField = null
    },
    getMenuNameText(value) {
      const option = this.optionsMenu.find(option => option.value === value)
      return option ? option.text : value
    },
    showToast(title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          variant: 'info',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
.inner-sm-padding {
  padding: 5px !important; /* 원하는 간격 설정 */
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

