<template>
  <div>
    <b-modal
      id="modal-team"
      ref="teamInfoPopup"
      title="게임 팀 조회"
      centered
      button-size="sm"
      size="lg"
      ok-title="저장"
    >
      <div style="min-height: 55vh;"> <!-- 내부 컨텐츠 스크롤 활성화 -->
        <!-- 팝업 모달 -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="6"
          >
            <b-form-group
              class="mb-0"
            >
              <b-input-group size="sm">
<!--                <b-col-->
<!--                    cols="3"-->
<!--                    md="5"-->
<!--                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"-->
<!--                >-->
<!--                  <label>검색옵션</label>-->
<!--                  <b-form-select-->
<!--                      v-model="searchOption"-->
<!--                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                      :options="searchOptionTitle"-->
<!--                      size="sm"-->
<!--                      :clearable="false"-->
<!--                      class="per-page-selector d-inline-block mx-50"-->
<!--                  />-->
<!--                </b-col>-->
<!--                <b-form-input-->
<!--                    v-model="inputValue"-->
<!--                    type="search"-->
<!--                    placeholder="Type to Search"-->
<!--                />-->
<!--                <b-input-group-append>-->
<!--                  <b-button-->
<!--                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
<!--                      variant="primary"-->
<!--                      size="sm"-->
<!--                      class="ml-1"-->
<!--                      @click="fetchData"-->
<!--                  >-->
<!--                    검색-->
<!--                  </b-button>-->
                <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
                <b-badge
                  variant="light-primary"
                  style="font-size: small; margin-left: 5px; margin-right: 3px; align-self: center"
                  class="badge-glow"
                >
                  {{ totalRows }}건
                </b-badge>
                <b-badge
                    v-if="fetchGameSetTeam && fetchGameSetTeam.length > 0"
                    variant="warning"
                    style="font-size: small; margin-left: 5px; margin-right: 3px; align-self: center"
                    class="badge-glow"
                >
                  {{ this.fetchGameSetTeam[0].leagueName }} /
                  {{ this.fetchGameSetTeam[0].leagueNameCustom }}
                </b-badge>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          responsive
          show-empty
          hover
          small
          empty-text="No matching records found"
          :per-page="perPage"
          :items="filteredTeams"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          style="text-align: center !important; font-size: small"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :current-page="currentPage"
          @filtered="onFiltered"
          @row-clicked="rowClickHandler"
        >
          <template #cell(leagueid)="data">
            <div
              style="min-width: 5rem"
            >
              {{ data.item.leagueId }}
            </div>
          </template>
          <template #cell(country)="data">
            <div style="text-align: left; width: 14rem;">
              <img
                class="league-logo-img"
                style="width: 28px; margin-right: 5px;"
                :src="`https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/${data.item.country}.svg`"
                @error="handleImageError"
              >
              <span
                style="text-align: left"
              >
                {{ data.item.country }}
              </span>
            </div>
          </template>
          <template #cell(leagueName)="data">
            <div
              style="text-align: left"
            >
              {{ data.item.leagueName }}
            </div>
          </template>
          <template #cell(leagueNameCustom)="data">
            <div
              style="text-align: left; color:rgb(229,166,48); min-width: 8rem"
            >
              {{ data.item.leagueNameCustom }}
            </div>
          </template>
          <template #cell(teamId)="data">
            <div
              style="min-width: 3rem"
            >
              {{ data.item.teamId }}
            </div>
          </template>
          <template #cell(teamName)="data">
            <div
              style="text-align: left"
            >
              {{ data.item.teamName }}
            </div>
          </template>
          <template #cell(teamNameCustom)="data">
            <div
              style="text-align: left; color:rgb(229,166,48); min-width: 8rem"
            >
              {{ data.item.teamNameCustom }}
            </div>
          </template>
          <template #cell(select)="data">
            <div
              class="selectClass"
              @click.stop
            >
              <div style="min-width: 2rem;">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class=""
                  size="sm"
                  variant="outline-success"
                  style="font-size: smaller"
                  @click="selectRow(data.item)"
                >
                  선택
                </b-button>
              </div>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2" style="position: absolute; bottom: 0px; width: calc(100% - 40px);">
          <b-row>
            <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BBadge,
  VBTooltip,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_GAME_SET_TEAM } from '@/store/game/action'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    VBTooltip,
    BModal,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  // props: {
  //   selectedLeagueId: {
  //     type: [String, null],
  //     default: null,
  //   },
  //   selectedSports: {
  //     type: [String, null],
  //     default: null,
  //   },
  // },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      socket: null,
      perPage: 17,
      pageOptions: [17, 100, 500],
      sortBy: '@id',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      showModal: false,
      options1: [
        { value: 'N', text: 'N' },
        { value: 'Y', text: 'Y' },
      ],
      fields: [
        // { key: 'index', sortable: false, label: 'No.' },
        { key: 'sports', sortable: false, label: '종목명' },
        { key: 'country', sortable: false, label: '국가' },
        { key: 'leagueId', sortable: false, label: '리그코드' },
        { key: 'leagueName', sortable: false, label: '리그명' },
        { key: 'leagueNameCustom', sortable: false, label: '리그명(설정값)' },
        { key: 'teamId', sortable: false, label: '팀코드' },
        { key: 'teamName', sortable: false, label: '팀명' },
        { key: 'teamNameCustom', sortable: false, label: '팀명(설정값)' },
        { key: 'select', sortable: false, label: '선택' },
      ],
      inputValue: '',
      searchOption: null,
      searchOptionTitle: [
        { value: null, text: '선택' },
        { value: 'sports', text: '종목명' },
        { value: 'country', text: '국가' },
        { value: 'leagueId', text: '리그코드' },
        { value: 'leagueName', text: '리그명' },
        { value: 'leagueNameCustom', text: '리그명(설정값)' },
        { value: 'teamId', text: '팀코드' },
        { value: 'teamName', text: '팀명' },
        { value: 'teamNameCustom', text: '팀명(변경)' },
      ],
    }
  },
  computed: {
    ...gameStore.mapGetters({
      fetchGameSetTeam: 'fetchGameSetTeam',
    }),
    filteredTeams() {
      let filteredItems = this.fetchGameSetTeam
      // 기본 텍스트 검색
      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }
      this.totalRows = filteredItems.length
      return filteredItems
    },
  },
  created() {
  },
  mounted() {
    this.fetchData()
  },
  setup() {
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameSetTeam: FETCH_GAME_SET_TEAM,
    }),
    async fetchData(selectedLeagueId, selectedSports) {
      if (!selectedLeagueId) {
        return
      }
      const mySite = this.siteSelected
      await this.$fetchGameSetTeam({
        site: mySite,
        leagueId: selectedLeagueId,
        sports: selectedSports,
        teamNameCustom: false,
        searchOption: this.searchOption,
      })
      this.totalRows = this.fetchGameSetTeam.length
      console.log('this.fetchGameSetTeam:::', this.fetchGameSetTeam)
    },
    onRowSelected(items) {
      this.selected = items
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handleImageError(e) {
      e.target.src = 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/default.svg'
    },
    rowClickHandler(record) {
    },
    selectRow(row) {
      // 팝업 모달을 닫습니다.
      // console.log('row:: ', row)
      const selectedTeamValue = row // 모달에서 선택한 값
      this.$emit('value-selected-team', selectedTeamValue)
      this.$refs.teamInfoPopup.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style>
.modal-dialog {
  max-width: 90rem;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
